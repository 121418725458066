import { ActionReducer } from '@ngrx/store';
import { Action } from '../actions/main-action-creator';
import {
  FrameworkConfigState, defaultFrameworkConfig, AsmntResponseState, OCCPageState, AsmntQuestionState, defaultQuestionText, defaultResponseText,
  defaultOccPage, SectionsFillStatusState, defaultSectionsStatus, AsmntLocationState, defaultLocationText, defaultOccTextState, defaultOccSettingsTextState, defaultAsmntIntroTextState, defaultOccIndexReducerTextState, defaultGetAllOccListState, defaultAsmntCommonTextState, defaultProgIndexReducerTextState, defaultProgTextState,
  defaultAidIndexReducerTextState, defaultAidTextState, defaultSchoolIndexReducerTextState, defaultSchoolTextState, defaultMilitaryIndexReducerTextState, defaultMilitaryTextState, defaultplanConfig
} from '../state/main-state';
// import { INCREMENT, EVENT_FROM_EFFECT } from "../actions/main-action-creator";
import { routeObj } from '../../shared/constants/app.constants';

import { StorageService } from '../../shared/outer-services/storage.service';


export function FrameworkConfigReducer(state = defaultFrameworkConfig, action: Action) {
  try {
    const checknull = StorageService.staticSessionStorageGet('loginFrameworkConfiguration');
    if (checknull != null && checknull != undefined) {
      state = JSON.parse(checknull);

    }

  } catch (e) {
    console.log('FrameworkConfigReducer reducer exception:' + e.message);
  }
  switch (action.type) {
    case 'SET_FRAMEWORK_CONFIG': {
      const newState = { config: action.payload };
      // @todo use the storage service here to ensure compatibility across all browsers.
      StorageService.staticSessionStorageSet('loginFrameworkConfiguration', JSON.stringify(newState));
      // StorageService.staticSessionStorageSet('langset', action.payload.Result.user.prefLang);
      StorageService.staticSessionStorageSet('langAssArr', JSON.stringify(action.payload.Result.user.langList));
      return { ...state, ...newState };
    }
    case 'SET_HEADERFOOTER_TEXT': {
      try {
        state.config['Result']['headerFooter'] = action.payload;
        StorageService.staticSessionStorageSet('loginFrameworkConfiguration', JSON.stringify(state));
      } catch (e) {
        console.log('Setting header text exception occured:' + e.message);
      }
      //console.log('In reducers SET_HEADERFOOTER_TEXT after data update:' + JSON.stringify(state));

      return state;
    }
    case 'SET_TABITEMS_TEXT': {
      //To hide the spinner only after the language got changed and the changed text is viewed inserting a property based on which only the spinner can be hidden.
      if (StorageService.staticSessionStorageGet('langset')) {
        state.config['Result'].lang = StorageService.staticSessionStorageGet('langset');
      }
      try {
        state.config['Result']['tabItems'] = action.payload;
        /*Here the usecase is the careers and Edcucation component List are copying into dashboard componentList */
        let dashboardIndex = -1, dashboardCompList = [];
        state.config.Result.tabItems.forEach((outerobj, inx1) => {
          if (outerobj.layout == 'dashboard') {
            dashboardIndex = inx1;
          }
          outerobj.compList.forEach(function (obj, inx) {
            if (routeObj[obj.compId] !== undefined) {
              const result1 = Object.assign({}, routeObj[obj.compId].itemConfig, obj);
              outerobj.compList[inx] = result1;
              if (obj.compId.includes('file') || obj.compId.includes('sort')) {
                dashboardCompList.push(outerobj.compList[inx]);
              }
            }
            /** this esle case is not assigning the component items to framwork object , this else case is not require   */
            // else {
            //   outerobj.compList.splice(inx, 1);
            // }

          }.bind(this));
          // return outerobj;
        });
        // dashboardIndex != -1 ? this.framework.Result.tabItems[dashboardIndex].compList = dashboardCompList : '';
        /* Here the dashboardIndex have the -1 value when we login TestDynamic .So we changed the ternary to if condition now. */
        if (dashboardIndex !== -1) {
          state.config.Result.tabItems[dashboardIndex].compList = dashboardCompList;
        }

        StorageService.staticSessionStorageSet('loginFrameworkConfiguration', JSON.stringify(state));
      } catch (e) {
        console.log('Setting tabItems text exception occured:' + e.message);
      }
      // console.log('In reducers SET_TABITEMS_TEXT after data update:' + JSON.stringify(state));
      return state;
    }
    default: {
      return state;
    }
  }
}


export function PlanConfigReducer(state = defaultplanConfig, action: Action) {
  try {
    const checknull = StorageService.staticSessionStorageGet('loginPlansConfiguration');
    if (checknull != null && checknull != undefined) {
      state = JSON.parse(checknull);

    }

  } catch (e) {
    console.log('planConfigReducer reducer exception:' + e.message);
  }
  switch (action.type) {
    case 'SET_PLAN_CONFIG': {
      // console.log('planConfigReducer reducer : --' + JSON.stringify(action.payload));
      state = { plan: action.payload };
      StorageService.staticSessionStorageSet('loginPlansConfiguration', JSON.stringify(state));
      return state;
    }
    default: {
      return state;
    }
  }
}

export function AsmntCommonText(state = defaultAsmntCommonTextState, action: Action) {
  try {
    const checknull = (StorageService.staticSessionStorageGet('AsmntCommonTextStorage'));
    if (checknull != null && checknull != undefined) {
      state = JSON.parse(checknull);
      // console.log('asst checknull --' + checknull);
    }

  } catch (e) {
    console.log('AsmntCommonText reducer exception:' + e.message);
  }
  switch (action.type) {
    case 'SET_COMMON_TEXT': {
      state = action.payload;
      StorageService.staticSessionStorageSet('AsmntCommonTextStorage', JSON.stringify(state));
      // console.log('Assessments common text:' + JSON.stringify(action.payload));
      return state;
    }
    default: {
      return state;
    }
  }
}
export function OccPageText(state = defaultOccPage, action: Action) {

  switch (action.type) {
    case 'OCC_EFFECT': {
      // console.log('OCC_EFFECT:--' + JSON.stringify(action.payload.id));

      state = action.payload;
      return state;
    }
    case 'EMP_OUTLOOK_EFFECT': {
      state['MajorEmployers'] = action.payload.MajorEmployers;
      state['OutlookInfo'] = action.payload.OutlookInfo;
      state['OutlookRatings'] = action.payload.OutlookRatings;
      // console.log("state in reducer--->" + JSON.stringify(state) + "------------------")
      return state;
    }
    case 'WAGES_EFFECT': {
      state['WageLevels'] = action.payload.WageLevels;
      return state;
    }
    case 'RESET_OCC_PAGE': {
      //console.log('AsmntParAreaText RESET_IPRESULT store:---->');

      state = { occPage: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}
export function OccText(state = defaultOccTextState, action: Action) {
  switch (action.type) {
    case 'OCC_TEXT_EFFECT': {
      state = { OccText: action.payload };
      StorageService.staticSessionStorageSet('Reducer_OccText', JSON.stringify(state));
      return state;
    }
    default: {
      return state;
    }
  }
}
export function OccSettingsText(state = defaultOccSettingsTextState, action: Action) {
  switch (action.type) {
    case 'OCC_SETTING_EFFECT': {
      state = { OccSettingsText: action.payload };
      return state;
    }
    case 'RESET_OccSettingsText': {
      // console.log('AsmntParAreaText RESET_IPRESULT store:---->');

      state = { OccSettingsText: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}

export function AsmntIntroText(state = defaultAsmntIntroTextState, action: Action) {
  try {
    const checknull = StorageService.staticSessionStorageGet('AsmntIntroText');
    if (checknull != null && checknull != undefined) {
      state = JSON.parse(checknull);

    }

  } catch (e) {
    console.log('asmntintrotext reducer exception:' + e.message);
  }
  switch (action.type) {
    case 'SET_INTRO_TEXT': {
      state = { AsmntIntroText: action.payload };

      StorageService.staticSessionStorageSet('AsmntIntroText', JSON.stringify(state));

      // console.log("AsmntIntroText intro text--->");
      return state;
    }
    case 'RESET_STORE': {
      StorageService.staticSessionStorageRemove('AsmntIntroText');
      state = { AsmntIntroText: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}

export function GetAllOccList(state = defaultGetAllOccListState, action: Action) {
  switch (action.type) {
    case 'OCC_LIST_STORE_EFFECT': {
      state = { GetAllOccList: action.payload };
      StorageService.staticSessionStorageSet('getListForAll', JSON.stringify(state));
      return state;

    }
    case 'OCC_LIST_WHY_EFFECT': {
      state = { GetAllOccList: action.payload };
      StorageService.staticSessionStorageSet('getListForAll', JSON.stringify(state));
      StorageService.staticSessionStorageSet('OSOnMyList', JSON.stringify(state));
      return state;

    }
    case 'OCC_LIST_WHYNOT_EFFECT': {
      state = { GetAllOccList: action.payload };
      StorageService.staticSessionStorageSet('getListForAll', JSON.stringify(state));
      StorageService.staticSessionStorageSet('OSNotOnMyList', JSON.stringify(state));
      return state;

    }
    case 'RESET_STORE': {
      StorageService.staticSessionStorageRemove('AsmntIntroText');
      state = { GetAllOccList: '' };
      return state;
    }
    default: {

    }
  }
}
export function OccIndexReducerText(state = defaultOccIndexReducerTextState, action: Action) {
  try {
    const checknull = StorageService.staticSessionStorageGet('OccIndexReducerText');
    if (checknull != null && checknull != undefined) {
      state = JSON.parse(checknull);

    }

  } catch (e) {
    console.log('OccIndexReducerText reducer exception:' + e.message);
  }
  switch (action.type) {
    case 'OCC_INDEX_STORE_EFFECT': {
      state = { OccIndexReducerText: action.payload };
      // console.log('OccIndexReducerText');
      StorageService.staticSessionStorageSet('OccIndexReducerText', JSON.stringify(state));
      return state;
    }
    default: {
      return state;
    }
  }
}

// export function OccFilterResponses(state = defaultCommonText, action: Action) {
//   try {
//     let checknull = StorageService.staticSessionStorageGet('OccFilterResponses');
//     if (checknull != null && checknull != undefined) {
//       state = JSON.parse(checknull);

//     }

//   } catch (e) {
//     console.log('OccFilterResponses reducer exception:' + e.message);
//   }
//   switch (action.type) {
//     case "OCC_FILTER_STORE_EFFECT": {
//       state = { commonText: action.payload }
//       StorageService.staticSessionStorageSet('OccFilterResponses', JSON.stringify(state));
//       return state;
//     }
//     default: {
//       return state;
//     }
//   }
// }

export function AsmntQuestionsResponses(state = defaultResponseText, action: Action) {
  try {
    const checknull = StorageService.staticSessionStorageGet('AsmntQuestionsResponses');
    if (checknull != null && checknull != undefined) {
      state = JSON.parse(checknull);

    }

  } catch (e) {
    console.log('asmntintrotext reducer exception:' + e.message);
  }
  // console.log('AsmntQuestionsResponses:');
  switch (action.type) {
    case 'SET_QUESTION_RESPONSES': {

      state = { commonResponseText: action.payload };
      StorageService.staticSessionStorageSet('AsmntQuestionsResponses', JSON.stringify(state));

      //  console.log('Assessments questions responses:' + JSON.stringify(action.payload));
      return state;
    }
    case 'RESET_STORE': {
      StorageService.staticSessionStorageRemove('AsmntQuestionsResponses');
      state = { commonResponseText: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}
export function AsmntQuestionsText(state = defaultQuestionText, action: Action) {
  // console.log('AsmntQuestionsResponses:');
  switch (action.type) {
    case 'SET_QUESTION_TEXT': {
      //  console.log('Assessments questions text:' + JSON.stringify(action.payload));
      state = { commonIntroText: action.payload };
      return state;
    }
    case 'RESET_STORE': {
      state = { commonIntroText: '' };
      return state;
    } case 'SET_RESTORE_TEXT': {
      //  console.log('Assessments restore text:' + JSON.stringify(action.payload));
      return action.payload;
    } case 'SET_AREA_TEXT': {
      // console.log('Assessments Area text:' + JSON.stringify(action.payload));
      return action.payload;

    } case 'SET_PARTICULAR_AREA_TEXT': {

      state = { commonIntroText: action.payload };
      //console.log('Assessments Area text:' + JSON.stringify(action.payload));
      return state;
    } case 'RESET_RESULT': {
      // console.log('AsmntParAreaText RESET_IPRESULT store:---->');

      state = { commonIntroText: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}
export function AsmntLocationText(state = defaultLocationText, action: Action) {
  // // console.log('AsmntLocationText in reducer:');
  try {
    const checknull = StorageService.staticSessionStorageGet('AsmntLocationText');
    if (checknull != null && checknull != undefined) {
      state = JSON.parse(checknull);

    }

  } catch (e) {
    console.log('asmntintrotext reducer exception:' + e.message);
  }
  switch (action.type) {
    case 'SET_LOCATION_TEXT': {
      //  console.log('Assessments questions text:' + JSON.stringify(action.payload));
      state = { commonLocationText: action.payload };
      StorageService.staticSessionStorageSet('AsmntLocationText', JSON.stringify(state));
      return state;
    }
    case 'RESET_STORE': {
      state = { commonLocationText: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}
// export function AsmntRestoreText(state = defaultQuestionText, action: Action) {
//   // console.log('AsmntQuestionsResponses:');
//   switch (action.type) {
//     case "SET_RESTORE_TEXT": {
//       //  console.log('Assessments restore text:' + JSON.stringify(action.payload));
//       return action.payload;
//     }
//     default: {
//       return state;
//     }
//   }
// }
// export function AsmntAreaText(state = defaultQuestionText, action: Action) {

//   // console.log('AsmntQuestionsResponses:');
//   switch (action.type) {
//     case "SET_AREA_TEXT": {
//       // console.log('Assessments Area text:' + JSON.stringify(action.payload));
//       return action.payload;

//     }
//     default: {
//       return state;
//     }
//   }
// }


// export function AsmntParAreaText(state = defaultQuestionText, action: Action) {

//   // console.log('AsmntQuestionsResponses:');
//   switch (action.type) {
//     case "SET_PARTICULAR_AREA_TEXT": {

//       state = { commonIntroText: action.payload }
//       //console.log('Assessments Area text:' + JSON.stringify(action.payload));
//       return state;
//     } case "RESET_RESULT": {
//       // console.log('AsmntParAreaText RESET_IPRESULT store:---->');

//       state = { commonIntroText: '' };
//       return state;
//     }

//     default: {
//       return state;
//     }
//   }
// }
export function SectionsStatusValues(state = defaultSectionsStatus, action: Action) {

  switch (action.type) {
    case 'SET_SCTIONS_STATES': {
      state = { statusValues: action.payload };
      return state;
    }
    case 'RESET_SCTIONS_STATES': {
      state = { statusValues: '' };
      return state;
    }
    default: {
      return state;
    }
  }
}

export function ProgIndexReducerText(state = defaultProgIndexReducerTextState, action: Action) {
  try {
    const checknull = StorageService.staticSessionStorageGet('ProgIndexReducerText');
    if (checknull != null && checknull != undefined) {
      state = JSON.parse(checknull);

    }

  } catch (e) {
    console.log('ProgIndexReducerText reducer exception:' + e.message);
  }
  switch (action.type) {
    case 'PROGS_INDEX_STORE_EFFECT': {
      state = { ProgIndexReducerText: action.payload };
      // console.log('OccIndexReducerText');
      StorageService.staticSessionStorageSet('ProgIndexReducerText', JSON.stringify(state));
      //  console.log("aid index reducer text is-->"+JSON.stringify(state));

      return state;
    }
    default: {
      // return state;
    }
  }

}
export function ProgText(state = defaultProgTextState, action: Action) {
  switch (action.type) {
    case 'PROG_TEXT_EFFECT': {
      state = { ProgText: action.payload };
      StorageService.staticSessionStorageSet('Reducer_ProgText', JSON.stringify(state));
      // console.log('PROG_TEXT_EFFECT-->'+JSON.stringify(state));
      return state;
    }
    default: {
      return state;
    }
  }
}
export function AidIndexReducerTextReducer(state = defaultAidIndexReducerTextState, action: Action) {
  try {
    const checknull = StorageService.staticSessionStorageGet('AidIndexReducerText');
    if (checknull != null && checknull != undefined) {
      state = JSON.parse(checknull);

    }

  } catch (e) {
    console.log('AidIndexReducerText reducer exception:' + e.message);
  }
  switch (action.type) {

    case 'AID_INDEX_STORE_EFFECT': {
      state = { AidIndexReducerText: action.payload };
      //  console.log('OccIndexReducerText');
      StorageService.staticSessionStorageSet('AidIndexReducerText', JSON.stringify(state));
      //  console.log("aid index reducer text is-->"+JSON.stringify(state));
      return state;
    }
    default: {
      // return state;
    }
  }
}
export function AidText(state = defaultAidTextState, action: Action) {
  switch (action.type) {
    case 'AID_TEXT_EFFECT': {
      state = { AidText: action.payload };
      StorageService.staticSessionStorageSet('Reducer_AidText', JSON.stringify(state));
      // console.log('AID_TEXT_EFFECT-->'+JSON.stringify(state));
      return state;
    }
    default: {
      return state;
    }
  }
}
export function SchoolIndexReducerTextReducer(state = defaultSchoolIndexReducerTextState, action: Action) {
  try {
    const checknull = StorageService.staticSessionStorageGet('SchoolIndexReducerText');
    if (checknull != null && checknull != undefined) {
      state = JSON.parse(checknull);

    }

  } catch (e) {
    console.log('SchoolIndexReducerText reducer exception:' + e.message);
  }
  switch (action.type) {

    case 'SCHOOL_INDEX_STORE_EFFECT': {
      state = { SchoolIndexReducerText: action.payload };
      StorageService.staticSessionStorageSet('SchoolIndexReducerText', JSON.stringify(state));
      // console.log("schools reducer text is-->"+JSON.stringify(state));
      return state;
    }
    default: {
      // return state;
    }
  }
}
export function SchoolText(state = defaultSchoolTextState, action: Action) {
  switch (action.type) {
    case 'SCHOOL_TEXT_EFFECT': {
      state = { SchoolText: action.payload };
      StorageService.staticSessionStorageSet('Reducer_SchoolText', JSON.stringify(state));
      // console.log('AID_TEXT_EFFECT-->'+JSON.stringify(state));
      return state;
    }
    default: {
      return state;
    }
  }
}
export function MilitaryIndexReducerTextReducer(state = defaultMilitaryIndexReducerTextState, action: Action) {
  try {
    const checknull = StorageService.staticSessionStorageGet('MilitaryIndexReducerText');
    if (checknull != null && checknull != undefined) {
      state = JSON.parse(checknull);

    }

  } catch (e) {
    console.log('MilitaryIndexReducerText reducer exception:' + e.message);
  }
  switch (action.type) {

    case 'MILITARY_INDEX_STORE_EFFECT': {
      state = { MilitaryIndexReducerText: action.payload };
      StorageService.staticSessionStorageSet('MilitaryIndexReducerText', JSON.stringify(state));
      //  console.log("military reducer text is-->"+JSON.stringify(state));
      return state;
    }
    default: {
      // return state;
    }
  }
}
export function MilitaryText(state = defaultMilitaryTextState, action: Action) {
  switch (action.type) {
    case 'MILITARY_TEXT_EFFECT': {
      state = { MilitaryText: action.payload };
      StorageService.staticSessionStorageSet('Reducer_MilitaryText', JSON.stringify(state));
      //  console.log('military_TEXT_EFFECT-->'+JSON.stringify(state));
      return state;
    }
    default: {
      return state;
    }
  }
}
